import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/globalStyles.css'

//https://www.youtube.com/watch?v=Umj51NqKF8Y
//https://matteo-gabriele.gitbook.io/vue-gtag/
import VueGtag from "vue-gtag";


createApp(App).use(store).use(router).use(VueGtag, {
    config: { id: "G-3EMX22F105" }
  }).mount('#app')
