import { createRouter, createWebHistory } from 'vue-router';
// import Home from '../views/Home.vue';
// import HowToOpenABinanceAccount from '../views/guides/HowToOpenABinanceAccount.vue';
// import HowToFundAndBuyBitcointWithABinanceAccount from '../views/guides/HowToFundAndBuyBitcointWithABinanceAccount.vue';
// import WhatIsCryptocurrency from '../views/guides/WhatIsCryptocurrency.vue';

const routes = [
  //HOME
  {
    path: '/',
    name: 'Home',
    // component: Home,
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    meta: {
      title: 'Vestooly | Step by step crypto guides',
      metaTagDescription:
        'Beginner guides on how to get started in the world of crypto currencies'
    }
  },
  //HowToOpenABinanceAccount
  {
    path: '/how-to-open-a-binance-account',
    name: 'HowToOpenABinanceAccount',
    component: () =>
      import(
        /* webpackChunkName: "HowToOpenABinanceAccount" */ '../views/guides/HowToOpenABinanceAccount.vue'
      ),
    // component: HowToOpenABinanceAccount,
    meta: {
      title: 'How To Create A Binance Account In 2023: Step By Step Guide',
      metaTagDescription:
        'Best 2023 guide on how to open a Binance account with desktop and mobile.'
    }
  },
  //HowToFundAndBuyBitCoinWithABinanceAccount
  {
    path: '/how-to-fund-and-buy-bitcoin-with-a-binance-account',
    name: 'HowToFundAndBuyBitCoinWithABinanceAccount',
    // route level code-splitting
    // this generates a separate chunk (HowToOpenABinanceAccount.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "HowToFundAndBuyBitCoinWithABinanceAccount" */ '../views/guides/HowToFundAndBuyBitcointWithABinanceAccount.vue'
      ),
    // component: HowToFundAndBuyBitcointWithABinanceAccount,
    meta: {
      title: 'How to buy bitcoin on binance with debit card / credit card',
      metaTagDescription:
        'A beginner step by step guide on how to buy bitcoin on binance using a debit or credit card. All steps include a screenshot with clear instructions.'
    }
  },
  //WhatIsCryptocurrencyAndHowDoesItWork
  {
    path: '/what-is-cryptocurrency-and-how-does-it-work',
    name: 'WhatIsCryptocurrencyAndHowDoesItWork',
    // route level code-splitting
    // this generates a separate chunk (WhatIsCryptocurrency.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "WhatIsCryptocurrency" */ '../views/articles/WhatIsCryptocurrency.vue'
      ),
    meta: {
      title: 'what is cryptocurrency and how does it work',
      metaTagDescription:
        'A simple beginners explanation of what cryptocurrency is and how it works.'
    }
  },
  //WhyPeopleInvestInCrypto
  {
    path: '/5-reasons-why-people-invest-in-crypto-currencies',
    name: 'WhyPeopleInvestInCrypto',
    // route level code-splitting
    // this generates a separate chunk (WhatIsCryptocurrency.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "WhyPeopleInvestInCrypto" */ '../views/articles/WhyPeopleInvestInCrypto.vue'
      ),
    // component: WhatIsCryptocurrency,
    meta: {
      title: '5 reasons people invest in cryptocurrencies',
      metaTagDescription:
        'A simple beginners explanation of the top 5 reasons why people invest in cryptocurrencies.'
    }
  },
  //HowDoIWithdrawMoneyFromBinance_WebsiteVersion
  {
    path:
      '/how-do-i-withdraw-money-from-binance-to-my-bank-account-website-version',
    name: 'HowDoIWithdrawMoneyFromBinance_WebsiteVersion',
    component: () =>
      import(
        /* webpackChunkName: "HowDoIWithdrawMoneyFromBinance_WebsiteVersion" */ '../views/guides/HowDoIWithdrawMoneyFromBinance_WebsiteVersion.vue'
      ),
    meta: {
      title:
        'How Do I Withdraw Money From Binance To My Bank Account - website version',
      metaTagDescription:
        'A beginner guide of how to convert crypto to normal currency on the Binance website, and withdraw it to your bank. All screenshots and steps clearly laid out.'
    }
  },
  //HowToBuyBitcoinOnBinanceWithBankTransfer
  {
    path: '/how-to-buy-bitcoin-on-binance-with-bank-transfer',
    name: 'HowToBuyBitcoinOnBinanceWithBankTransfer',
    component: () =>
      import(
        /* webpackChunkName: "HowToBuyBitcoinOnBinanceWithBankTransfer" */ '../views/guides/HowToBuyBitcoinOnBinanceWithBankTransfer.vue'
      ),
    meta: {
      title: 'How To Buy Bitcoin On Binance With Bank Transfer',
      metaTagDescription:
        'A step by step beginners guide on how to transfer money to Binance using a bank account and buy bitcoin. All screenshots and steps clearly laid out.'
    }
  },
  //HowToActivateBinanceFiatService
  {
    path: '/how-to-activate-binance-fiat-service',
    name: 'HowToActivateBinanceFiatService',
    component: () =>
      import(
        /* webpackChunkName: "HowToActivateBinanceFiatService" */ '../views/guides/HowToActivateBinanceFiatService.vue'
      ),
    meta: {
      title: 'How to activate / upgrade binance fiat service',
      metaTagDescription:
        'Simple guide on how to activate / upgrade the fiat service in your Binance account to buy crypto. All screenshots and steps clearly laid out.'
    }
  },
  //How to transfer from binance to another wallet address
  {
    path: '/how-to-transfer-from-binance-to-another-wallet-address',
    name: 'HowToTransferFromBinanceToAnotherWalletAddress',
    component: () =>
      import(
        /* webpackChunkName: "HowToTransferFromBinanceToAnotherWalletAddress" */ '../views/guides/HowToTransferFromBinanceToAnotherWalletAddress.vue'
      ),
    meta: {
      title: 'How to transfer from binance to another wallet address',
      metaTagDescription:
        'A step by step beginners guide on how to transfer cryptocurrency from a Binance account to a crypto wallet using Bitcoin and Trust Wallet as examples.'
    }
  },
  //how to invest in bitcoin for beginners
  {
    path: '/how-to-invest-in-bitcoin-for-beginners-dummies',
    name: 'HowToInvestInBitcoinForBeginners',
    component: () =>
      import(
        /* webpackChunkName: "HowToInvestInBitcoinForBeginners" */ '../views/articles/HowToInvestInBitcoinForBeginners.vue'
      ),
    meta: {
      title: 'How to invest in bitcoin for beginners / dummies',
      metaTagDescription:
        'A beginners guide on how to start investing in bitcoin. Steps include an overview of Bitcoin, buying Bitcoin on an exchange, getting a crypto wallet'
    }
  },
  //HowToTransferCryptoFromTrustWalletToBinance
  {
    path: '/how-to-transfer-crypto-from-trust-wallet-to-binance',
    name: 'HowToTransferCryptoFromTrustWalletToBinance',
    component: () =>
      import(
        /* webpackChunkName: "HowToTransferCryptoFromTrustWalletToBinance" */ '../views/guides/HowToTransferCryptoFromTrustWalletToBinance.vue'
      ),
    meta: {
      title: 'How to transfer crypto from Trust Wallet to binance',
      metaTagDescription:
        'A beginners guide on how to transfer crypto from your crypto wallet to Binance. Step by step clear instructions using Trust Wallet and Binance as examples.'
    }
  },
  //HowToBuyTruthGptCryptoCoin
  {
    path: '/how-to-buy-truth-gpt-crypto-coin',
    name: 'HowToBuyTruthGptCryptoCoin',
    component: () =>
      import(
        /* webpackChunkName: "HowToBuyTruthGptCryptoCoin" */ '../views/guides/HowToBuyTruthGptCryptoCoin.vue'
      ),
    meta: {
      title: 'How To Buy Truth Gpt Crypto Coin for beginners',
      metaTagDescription:
        'A step by step guide showing how to buy Truth GPT crypto coin. Which platforms to buy on, how to check its price, and what it is.'
    }
  },
  //PEPEAndSPONGEExplained
  {
    path: '/pepe-and-sponge-crypto-coin-explained',
    name: 'PEPEAndSPONGEExplained',
    component: () =>
      import(
        /* webpackChunkName: "PEPEAndSPONGEExplained" */ '../views/articles/PEPEAndSPONGEExplained.vue'
      ),
    meta: {
      title: 'What is the Latest Meme Coin Craze? $PEPE and $SPONGE Explained',
      metaTagDescription:
        'Discover the latest craze in crypto meme coins - PepeCoin to SpongeCoin, explore the world of meme-based cryptocurrencies and their potential for big returns'
    }
  },
  //Listicle template
  {
    path: '/best-crypto-exchanges',
    name: 'BestCryptoExchanges',
    component: () =>
      import(
        /* webpackChunkName: "ListicleTemplate" */ '../views/articles/BestCryptoExchanges.vue'
      ),
    meta: {
      title: '3 Best Crypto Exchanges For Beginners To Buy Coins',
      metaTagDescription:
        'Want the easiest and most staight forward way to buy cryptocurrencies? Here you can see the best beginner friendly exchanges with pros and cons for each'
    }
  }
  //   {
  //     path: '/about',
  //     name: 'About',
  //     // route level code-splitting
  //     // this generates a separate chunk (about.[hash].js) for this route
  //     // which is lazy-loaded when the route is visited.
  //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  //   }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

var currentMetaTag = undefined;

//Update the page title for each route (https://www.youtube.com/watch?v=W6qpsmkuJ8c)
//Route guards / beforeeach official docs: https://v3.router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  updateMetaTagForCurrentPage(to);
  next();
});

function updateMetaTagForCurrentPage(to) {
  //so we don't keep adding a new meta tab each time

  if (currentMetaTag == undefined) {
    currentMetaTag = document.createElement('meta');
  }
  currentMetaTag.setAttribute('name', 'description');
  currentMetaTag.content = to.meta.metaTagDescription;
  document.getElementsByTagName('head')[0].appendChild(currentMetaTag);
}

export default router;
